<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Edit To-do event</span>
				<span class="pl-2" v-else>Add To-do event</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<!-- <p class="font-weight-black title" >School info</p> -->
						<v-select
							dense
							:items="weeks"
							item-text="week_name"
							item-value="id"
							v-model="form.week_id"
							filled
							label="Week"
						></v-select>
						<v-text-field dense v-model="form.name" label="To-do"
											filled>
										</v-text-field>
						<!-- <v-text-field dense v-model="form.perc_completion" label="% afgerond"
											filled>
										</v-text-field> -->
						<v-select
							dense
							:items="percs"
							
							v-model="form.perc_completion"
							filled
							label="% afgerond"
						></v-select>
						<v-textarea aria-multiline="true"  dense v-model="form.description" label="Omschrijving"
											filled> 
						</v-textarea>
						</v-col> 
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn>
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-card>
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			user: JSON.parse(localStorage.getItem("user")),
			snackbar: false,
			weeks:[],
			percs:[0,25,50,75,100],
			users:[],
			form: {
				id: 0,
				week_id: null,
				user_id: null,
				name: null,
				perc_completion: 0,
				
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getWeeks();
		
		
		if (this.id > 0) {
				this.getAdmin_task(this.id);
			}
	},
	watch: {
		
	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		getWeeks: function() {
			var self = this;

			ax.get("/week/all").then(function(response) {
				console.log(response.data);
				self.weeks = response.data;
			});
		},

		getAdmin_task: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/admin_task/single/" + id).then(function (response) {
					// console.log(response.data);
					self.form = response.data;
					self.loading = false;
				});
			},

		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				console.log(response.data);
				self.users = response.data;
			});
		},
		submitForm() {
			var self = this;
			this.form.user_id = this.user.id;
			
			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/admin_task/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/admin_task"
					});
				});
			} else {
				ax.post("/admin_task/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/admin_task"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
