<template>
	<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2" v-if="id > 0">Student Finance View</span>
				<span class="pl-2" v-else>Student Finance View..</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-text>
			<v-form>
				<v-row>
					
					<v-col cols="12" sm="6">
						<!-- <p class="font-weight-black title" >School info</p> -->
						<v-text-field dense v-model="form.firstname" label="Firstname" readonly
											filled> 
										</v-text-field>
						<v-text-field dense v-model="form.surname" label="Lastname" readonly
											filled>
										</v-text-field>
						<v-text-field dense v-model="form.government_id" label="Government ID"
									readonly		filled>
										</v-text-field>
						</v-col>
						<v-col cols="12" sm="6">
						<v-text-field dense v-model="form.tel_mobile" label="Mobile phone"
											filled readonly> 
										</v-text-field>
						<v-text-field dense v-model="form.osbod_email" label="Osbod email"
											filled readonly>
										</v-text-field>
						
					</v-col> 
				</v-row>
				<v-row>
					<v-col cols="12" sm="12">
						<v-card>
		<v-toolbar flat color="dark" dark>
			<v-toolbar-title>
				<span class="pl-2">Financial Transactions</span>
			</v-toolbar-title>
		</v-toolbar>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
		</v-card-title>

		<v-data-table :headers="headers" :items="data" :search="search" :loading="loading">
			<template v-slot:top>
				<v-toolbar dark dense color="primary" flat class="mb-1">
					<v-btn depressed class="ml-2" @click="setFinanceStudentTransaction()" color="inverted">
						<v-icon class="pr-2">mdi-plus-circle</v-icon>Add Transaction
					</v-btn>
				</v-toolbar>
			</template>
			<template v-slot:item.action="{ item }">
				<v-icon @click="handleClick(item.id)">mdi-account-edit</v-icon>
			</template>
		</v-data-table>
	</v-card>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<!-- <v-btn color="primary" @click="submitForm(id)" depressed>Save</v-btn> -->
			<v-btn color="dark" @click="$router.go(-1);" depressed>Cancel</v-btn>
		</v-card-actions>

		<v-snackbar v-model="snackbar" bottom color="red" right :timeout="10000" top>
			Schooljaar en Klas van het student zijn niet geselecteerd! Ga naar de het tab "Klas" om deze in te stellen!
			<v-btn dark text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
		
	</v-card>

	
</template>
 
 <script>
import Axios from "axios";
//import _ from "lodash";
// import moment from "moment";
import { API_ROOT } from "../../config";
console.log(API_ROOT.osbod_salary);
const ax = Axios.create({
	baseURL: API_ROOT["osbod_salary"],
	timeout: 60000
});

export default {
	name: "mutationedit",
	props: ["id"],
	data() {
		return {
			search: "",
			user: JSON.parse(localStorage.getItem("user")),
			
			snackbar: false,
			sectors:[],
			levels:[],
			studys:[],
			grades:[],
			schoolclasss:[],
			student_finances:[],
			users:[],
			data: [],
			headers: [
				{
					text: "",
					align: "center",
					sortable: false,
					value: "action"
				},
				{ text: "Schooljaar", value: "tbl_sys_schoolyear.name" },
				{ text: "Kostenplaat", value: "tbl_sys_finance_task.name" },
				{ text: "Opleiding", value: "tbl_sys_study.name" },
				{ text: "Transactie datum", value: "registration_date" },
				{ text: "Transactie", value: "tbl_sys_transaction_type.name" },
				{ text: "Bedrag (ANG)", value: "amount" },
				{ text: "Omschrijving", value: "description"},
				// { text: "Second Check",	value: "secondcheckby"},
				// { text: "Active", value: "tbl_student_class.student_class_active" }

			],
			form: {
				id: 0,
				firstname: null,
				surname: null,
				government_id: null,
				tel_mobile: null,
				osbod_email: null
			}
		};
	},
	created: function() {
		this.getUsers();
		this.getsectors();
		this.getLevel();
		this.getGrade();
		
		
		if (this.id > 0) {
				this.getStudent(this.id);
				// this.getStudentFinance();
			}
	},
	watch: {
		"form.sector_id": function(value){
				this.getStudy(value);
				// this.getLevel1(value);
				
			},
		// "form.study_id": function(value){
		// 		this.getLevel(value);
		// 		// this.getLevel1(value);
				
		// 	}
	},
	methods: {
		Formatter: function(test) {
			console.log(test);

			return test;
		},
		handleClick(id) {
				this.$router.push({
					path: "/schooladmin/financestudent/update/" + id
				});
			},
		setFinanceStudentTransaction: function() {
			
			this.$router.push({
				path: "/schooladmin/financestudent/new" , query: {my_selected_student_id : this.form.id }
			});
		},
		getsectors: function() {
			var self = this;

			ax.get("/sector/all").then(function(response) {
				// console.log(response.data);
				self.sectors = response.data;
			});
		},
		getStudy: function() {
			var self = this;

			ax.get("/study/selected_sector/", {
				params: {
					sector_id: self.form.sector_id,
				}
			}).then(function(response) {
				self.studys = response.data;
				console.log("After Fill");
	
			});
			
			
		},
		getStudent: function (id) {
				var self = this;

				this.loading = true;
				ax.get("/student/single/" + id).then(function (response) {
					console.log("school_class_single");
					console.log(response.data);
					self.form = response.data;
			// Get student Finance
			console.log("this.form.id");
			console.log(self.form.id);
			
			ax.get("/student_finance/study_cost/", {
				params: {
					student_id: self.form.id,
				}
			}).then(function(response) {
				self.data = response.data;
				console.log("SchoolyearStudyCosts fetched...");
				console.log(response.data);
				self.loading = false;
	
			});

					self.loading = false;
				});
				
			},
		getStudentFinance: function() {
			var self = this;
			console.log("this.form.id");
			console.log(self.form.id);
			ax.get("/student_finance/study_cost/", {
				params: {
					student_id: self.form.id,
				}
			}).then(function(response) {
				self.data = response.data;
				console.log("SchoolyearStudyCosts fetched...");
				console.log(response.data);
				self.loading = false;
	
			});
			
			
		},
		getLevel: function() {
			var self = this;

			ax.get("/level/all").then(function(response) {
				// console.log(response.data);
				self.levels = response.data;
			});
		
		},
		getGrade: function() {
			var self = this;

			ax.get("/grade/all").then(function(response) {
				console.log("Grade Fill")
				// console.log(response.data);
				self.grades = response.data;
			});
		},
		getUsers: function() {
			var self = this;

			ax.get("/users/all").then(function(response) {
				// console.log(response.data);
				self.users = response.data;
			});
		},
		submitForm() {
			var self = this;

			//this.$nextTick(function() {
			if (this.form.id > 0) {
				ax.post("/school_class/update/" + this.form.id, this.form).then(function(
					response
				) {
					console.log(response.data);

					console.log("Mutations update...");

					self.$router.push({
						path: "/schooladmin/schoolclass"
					});
				});
			} else {
				ax.post("/school_class/create", this.form).then(function(response) {
					console.log(response.data);
					self.$router.push({
						path: "/schooladmin/schoolclass"
					});
					console.log("Mutations created...");
				});
			}
			//});
		}
	}
};
</script>
